import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Box, width } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { Link, useNavigate, useParams } from "react-router-dom";
import countryList from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import { url } from "../../baseUrl";
import axios from "axios";
import { lightBackground, white } from "../../Assets/Theme/ThemeColors";
import { userUnAuthorized } from "../../redux/actions/commonAction";

const CreateCustomer = () => {
  let { id } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Number, setNumber] = useState("");
  const [country, setCountry] = useState("");
  const [group, setGroup] = useState("");

  const [isDisable, setIsDisable] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  let param = id.split("_");
  let custid = param[0];
  let accessType = param[param.length - 1];
  const options = useMemo(() => countryList().getData(), []);
  console.log(options);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user.data);

  useEffect(() => {
    if (accessType !== "CREATE") {
      getCustomer();
    }
  }, []);

  const getCustomer = async () => {
    const response = await axios
      .post(
        `${url}/customers/getSpecific`,
        { _id: custid },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then((response) => {
        let data = response.data.data.data;
        console.log("customer", data);
        setIsDisable(true);

        setName(data.cust_name);
        setEmail(data.cust_email);
        setNumber(data.cust_number);
        setCountry(data.country);

        //reaOnly and update

        if (accessType === "readOnly") {
          setIsReadOnly(true);
        } else {
          setIsReadOnly(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("unauthorized error");
          userUnAuthorized(dispatch);
        } else {
          console.log("network error");
          setIsLoaded(false);
        }
      });
  };

  const formSubmit = async () => {
    setIsLoaded(true);
    var data =
      accessType === "CREATE"
        ? {
            cust_name: name,
            cust_email: email,
            cust_number: Number,
            country: country,
          }
        : {
            cust_name: name,
            cust_email: email,
            cust_number: Number,
            country: country,
            _id: custid,
          };
    await axios
      .post(
        `${url}${accessType === "CREATE" ? "/customers" : "/customers/edit"}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setIsReadOnly(true);

        //snackbar
        setTimeout(() => {
          navigate("/customer/list");
        }, 2000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("unauthorized error");
          userUnAuthorized(dispatch);
        } else {
          console.log("network error");
          setIsLoaded(false);
        }
      });

    setIsLoaded(false);
  };

  return (
    <Container
      style={{
        maxWidth: "100%",
        padding: "0px",
        overflowY: "scroll",
        height: "90vh",
      }}
    >
      <Box sx={{ mt: 3 }}>
        <Paper sx={{ p: 3, mr: 2, ml: 2 }}>
          <Grid container sx={{ mt: 2 }}>
            <Typography variant="h5" color="Primary">
              {accessType === "CREATE" ? "Add New Customer" : "Customer"}
            </Typography>
          </Grid>
          {/* <Grid item className="Row" sx={{ p: 1 }}>
            <Grid item container md={9} xs={12}>
              <TextField
                name="date"
                label="Date"
                // type="date"
                margin="normal"
                disabled={true}
                value={`${
                  new Date().getMonth() +
                  1 +
                  " / " +
                  new Date().getDate() +
                  " / " +
                  new Date().getFullYear()
                }`}
              />
            </Grid>
          </Grid> */}
          <Grid container className="Row">
            <Grid item container md={4} xs={12} sx={{ p: 1 }}>
              <TextField
                name="clientName"
                label="Customer Name"
                type="text"
                margin="normal"
                fullWidth
                value={name}
                disabled={isReadOnly}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item container md={4} xs={12} sx={{ p: 1 }}>
              <TextField
                name="clientEmail"
                label="Customer Email"
                type="text"
                margin="normal"
                fullWidth
                disabled={isReadOnly}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item container md={4} xs={12} sx={{ p: 1 }}>
              <PhoneInputWithCountrySelect
                placeholder="Enter Phone Number"
                value={Number}
                disabled={isReadOnly}
                onChange={(value) => setNumber(value)}
              />
            </Grid>
            <Grid item container md={4} xs={12} sx={{ p: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label">
                  Select Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={country}
                  disabled={isReadOnly}
                  onChange={(e) => setCountry(e.target.value)}
                  sx={{ textAlign: "left" }}
                  label="Select Country"
                >
                  <MenuItem value="">
                    <em>none</em>
                  </MenuItem>
                  {options
                    ? options.map((item) => (
                        <MenuItem value={item.label}>{item.label}</MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            className="Row"
            sx={{ mt: 2 }}
            style={{
              gap: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item container md={1} xs={1}>
              <Button
                type="clear"
                style={{
                  border: `1px solid ${lightBackground}`,
                  color: `${lightBackground}`,
                  background: `${white}`,
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                onClick={() => {
                  navigate("/customer/list");
                }}
              >
                back
              </Button>
            </Grid>
            {accessType !== "readOnly" && !isReadOnly ? (
              <Grid item container md={1} xs={1}>
                <Button
                  onClick={formSubmit}
                  variant="contained"
                  style={{
                    background: `${lightBackground}`,
                    color: `${white}`,
                  }}
                  margin="normal"
                  fullWidth
                  disabled={isLoaded}
                >
                  {isLoaded ? (
                    <span>
                      {" "}
                      <CircularProgress
                        style={{
                          color: `${white}`,
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </span>
                  ) : accessType === "CREATE" ? (
                    "save"
                  ) : (
                    "UPDATE"
                  )}
                </Button>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};

export default CreateCustomer;
