import React, { useEffect, useRef } from "react";
import { baseurl } from "../../baseUrl";
import { socket } from "../socket";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotification as StoreNoti,
  clearNotification,
} from "../../redux/actions/notificationAction";
import { addUsers } from "../../redux/actions/activeUsersAction";
import sound1 from "../../Assets/Sounds/Notification1.mp3";
import { Howl } from "howler";

import addNotification from "react-push-notification";
import icon from "../../Assets/images/icon.png";
import {
  clearReloadList,
  reloadList,
  userConnected,
  userDisConnected,
} from "../../redux/actions/commonAction";

const MainSocket = () => {
  const name = useSelector((state) => state?.user?.data?.data);
  const commonState = useSelector((state) => state?.common);
  const dispatch = useDispatch();

  const sound = new Howl({
    src: [sound1],
  });

  const playSound = () => {
    if (sound) {
      sound.play();
    }
  };
  const clickToNotify = () => {
    console.log("clickToNotify");

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("granted");
        addNotification({
          title: "MOZACK-CRM",
          subtitle: "NEW LEAD", //optional
          message: "You have a new lead.", //optional
          onclick: () => (window.location = "crm.moazack.co"),
          duration: 6000, //optional, default: 5000,
          // closeButton: "Go away", //optional, text or html/jsx element for close text. Default: Close,
          native: true, //optional, makes the push notification a native OS notification
          icon: icon, // optional, Native only. Sets an icon for the notification.
          silent: true, // optional, Native only. Makes the notification silent.
        });
      }
    });

    // Notification.requestPermission().then((permission) => {
    //   if (permission === "granted") {
    //     // Display a notification
    //     const notification = new Notification("MOZACK-CRM", {
    //       body: "You have a new lead.",
    //       icon: icon, // optional
    //     });

    //     // Handle click event on the notification
    //     notification.onclick = () => {
    //       window.location.href = "https://crm.moazack.co";
    //     };

    //     // Close the notification after a specified duration (e.g., 6000 milliseconds)
    //     setTimeout(() => {
    //       notification.close();
    //     }, 6000);
    //   }
    // });
  };

  useEffect(() => {
    socket.connect();

    socket.on("disconnect", () => {
      userDisConnected(dispatch);
      console.log("Disconnected from server");
    });

    socket.on("reconnect", (attemptNumber) => {
      if (name?.user._id) {
        socket.emit("online", name?.user._id);
        userConnected(dispatch);
        clearNotification(dispatch);
        console.log(`Reconnected to server after ${attemptNumber} attempts `);

        const currentURL = window.location.pathname;
        if (currentURL === "/mainlead/list") {
          reloadList(dispatch);
          clearNotification(dispatch);
          setTimeout(() => {
            clearReloadList(dispatch);
          }, 1000);
        }
      } else {
        socket.off("activeUsers");
        socket.off("notification");
        socket.disconnect();
      }
    });

    socket.on("reconnect_error", (error) => {
      userDisConnected(dispatch);
      console.log(`Reconnection error: ${error.message} `);
    });

    socket.on("reconnect_failed", () => {
      console.log("Reconnection failed ");
      userDisConnected(dispatch);
    });

    socket.on("notification", (data) => {
      if (
        name?.user?._id &&
        name?.user.user_type === "admin" &&
        commonState.isAuthorized
      ) {
        console.log("notification", data);
        StoreNoti(data, dispatch);
        clickToNotify();
        playSound();
      } else if (
        name?.user?._id &&
        name?.user.user_type !== "admin" &&
        name?.user?.branch_code.toLowerCase() ===
          data.branch_code.toLowerCase() &&
        commonState.isAuthorized
      ) {
        StoreNoti(data, dispatch);
        clickToNotify();
        playSound();
      } else if (!name?.user?._id && !commonState.isAuthorized) {
        socket.off("activeUsers");
        socket.off("notification");
        socket.disconnect();
      }
    });

    socket.on("activeUsers", (data) => {
      userConnected(dispatch);
      if (name?.user.user_type === "admin") {
        console.log("active users", data);
        addUsers(data, dispatch);
      }
    });

    return () => {
      socket.off("activeUsers");
      socket.off("notification");
      socket.disconnect();
    };
  }, [name?.user._id]);

  useEffect(() => {
    if (name?.user._id) {
      socket.emit("online", name?.user._id);
    } else {
      userDisConnected(dispatch);
    }
  }, [name?.user._id]);

  return <div></div>;
};

export default MainSocket;
