import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { lightBackground, white } from "../../Assets/Theme/ThemeColors";
import BasicDatePicker from "../../Components/DatePicker/DatePicker";
import NumberCard from "../../Components/NumberCard/NumberCard";

import axios from "axios";
import { url } from "../../baseUrl";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import SessionExpired from "../../Components/sessionExpire/SessionExpired";
import { userUnAuthorized } from "../../redux/actions/commonAction";

const Dashboard = () => {
  const state = useSelector((state) => state.user.data);

  const [numberCards, setNumberCards] = useState([]);
  const [monthlyCards, setMonthlyCards] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-2`)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 30)
  );

  const dispatch = useDispatch();

  const filteredCards = [
    {
      title: "Total Leads",
      amount: "102",
      date: "MAY",
      linkName: "view sheet",
      sheetLink: "/mainlead/list",
    },
    {
      title: "Total Coverted Leads",
      amount: "80",
      date: "MAY",
      linkName: "view sheet",
      sheetLink: "/convertedlead/list",
    },
    {
      title: "M.O.M Revenue",
      amount: "$104,009",
      date: "MAY",
      linkName: "view sheet",
      sheetLink: "/convertedlead/list",
    },
  ];

  useEffect(() => {
    getTotalSales();
    getMonthly();
  }, []);

  const getMonthly = async () => {
    setIsLoaded(true);
    try {
      await axios
        .post(
          `${url}/convertedLeads/customPerformance`,
          { startDate: startDate, endDate: endDate },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((response) => {
          // console.log("response", response.data.data);
          if (response.data.data.success) {
            // response.data.data.data.map((item) => {
            setMonthlyCards(response.data.data.data);
            // setMonthlyCards.push(item);
            // console.log(response,"response")
            // });
          } else {
            console.log(response.data.data.message);
            setIsOpen(true);
            setMessage(response.data.data.message);
            setSeverity("error");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
    } catch (error) {
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setIsLoaded(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  const getTotalSales = async () => {
    setIsLoaded(true);

    try {
      await axios
        .get(`${url}/convertedLeads/overAllPerformance`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((response) => {
          console.log("response", response.data.data);
          if (response.data.data.success) {
            // .map((item) => {
            setNumberCards(response.data.data.data);
            // });
          } else {
            console.log(response.data.data.message);
            setIsOpen(true);
            setMessage(response.data.data.message);
            setSeverity("error");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
    } catch (error) {
      console.log(error.message);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setIsLoaded(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <>
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        {!isLoaded ? (
          <Grid container sx={{ p: 2 }}>
            {/* <Grid item md={3} sx={{ p: 2 }}>
          <NumberCard
            title={"Total Leads"}
            amount={"$104,009"}
            date={"MAY 15 , 2022"}
            linkName={"view sheet"}
            sheetLink={"/mainlead/list"}
          />{" "}
        </Grid> */}
            {numberCards.map((card) => (
              <Grid item md={4} xs={12} sx={{ p: 2 }}>
                <NumberCard
                  title={card.title}
                  amount={card.amount}
                  date={card.date}
                  linkName={card.linkName}
                  sheetLink={card.sheetLink}
                />
              </Grid>
            ))}
            <Grid
              container
              sx={{
                p: 2,
                // minHeight: "20vh",
                // width: "100vw",
                // display: "flex",
                // justifyContent: "space-around",
              }}
              xs={12}
            >
              <Paper item sx={{ height: "100%", width: "100%" }}>
                <Grid
                  container
                  sx={{
                    p: 2,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    item
                    md={6}
                    xs={3}
                    sx={{ p: 1 }}
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">Select Date</Typography>
                  </Grid>
                  <Grid container item md={2} xs={3} sx={{ p: 2 }}>
                    <BasicDatePicker
                      label={"Start Date"}
                      value={startDate}
                      setValue={setStartDate}
                      item
                    />
                  </Grid>
                  <Grid container item md={2} xs={3} sx={{ p: 2 }}>
                    <BasicDatePicker
                      label={"End Date"}
                      value={endDate}
                      setValue={setEndDate}
                      item
                    />
                  </Grid>
                  <Grid item md={2} xs={3} sx={{ p: 1 }}>
                    <Button
                      onClick={getMonthly}
                      variant="contained"
                      fullWidth
                      // onClick={handleSessionOpen}
                      style={{
                        background: `${lightBackground} `,
                        color: `${white} `,
                      }}
                      // color={`${ darkButton } `}
                    >
                      Get Data{" "}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {monthlyCards.map((card) => (
              <Grid item md={4} xs={12} sx={{ p: 2 }}>
                <NumberCard
                  title={card.title}
                  amount={card.amount}
                  date={card.date}
                  linkName={card.linkName}
                  sheetLink={card.sheetLink}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </Container>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
      {/* <SessionExpired open={sessionOpen} handleClose={sessionClosed} /> */}
    </>
  );
};

export default Dashboard;
