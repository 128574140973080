import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import LoginReducer from "./reducers/authReducer";
import NotificationReducer from "./reducers/notificationReducer";
import ActiveUsersReducer from "./reducers/activeUsersReducer";
import CommonReducer from "./reducers/commonReducer";

const persistConfig = {
  key: "root",
  storage: storage,
  debug: true,
};

// const enhancer = compose(applyMiddleware(thunk));
const initialState = {};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user: LoginReducer,
    notification: NotificationReducer,
    activeUsers: ActiveUsersReducer,
    common : CommonReducer
  })
);

const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);
const persistor = persistStore(store);

export { store, persistor };
