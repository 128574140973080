import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../baseUrl";
import DatatablePage from "../../Components/DataTable/DataTable";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreateIcon from "@mui/icons-material/Create";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
  darkButton,
  lightBackground,
  lightText,
  white,
} from "../../Assets/Theme/ThemeColors";
import { useDispatch, useSelector } from "react-redux";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import BasicDatePicker from "../../Components/DatePicker/DatePicker";
import { userUnAuthorized } from "../../redux/actions/commonAction";
const ConvertedLeadsList = () => {
  const state = useSelector((state) => state.user.data);
  const userType = state.data.user.user_type;
  console.log(state.data.user.user_type);

  const [data, setData] = useState();
  const month = new Date().getMonth() + 1;
  const [year, setYear] = useState(
    new Date().getFullYear() +
      (String(month).length === 1 ? "-0" + month : "-" + month)
  );

  console.log(month, String(month).length);
  console.log(year);

  const [startDate, setStartDate] = useState(
    new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-2`)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 30)
  );

  const [isSheetLoaded, setIsSheetLoaded] = useState(false);

  const [branch, setBranch] = useState("KHI");


  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 100,
    },
    {
      label: "Order Id",
      field: "order_id",
      sort: "asc",
      width: 100,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 100,
    },
    {
      label: "Time",
      field: "time",
      sort: "asc",
      width: 270,
    },
    {
      label: "Client Name",
      field: "customer_name",
      sort: "asc",
      width: 100,
    },

    {
      label: "Email Address",
      field: "email",
      sort: "asc",
      width: 100,
    },
    {
      label: "Phone Number",
      field: "phone_number",
      sort: "asc",
      width: 150,
    },
    {
      label: "Brand",
      field: "brand",
      sort: "asc",
      width: 100,
    },
    {
      label: "Lead Status",
      field: "lead_status",
      sort: "asc",
      width: 100,
    },
    {
      label: "Production / Service",
      field: "product_type",
      sort: "asc",
      width: 100,
    },
    {
      label: "Words Count",
      field: "word_count",
      sort: "asc",
      width: 100,
    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      width: 100,
    },
    {
      label: "Remaining Balance",
      field: "remaining_balance",
      sort: "asc",
      width: 100,
    },
    {
      label: "Merchant",
      field: "merchant",
      sort: "asc",
      width: 100,
    },
    {
      label: "Agent",
      field: "agent",
      sort: "asc",
      width: 100,
    },

    {
      label: "Delivery Date",
      field: "delivery_date",
      sort: "asc",
      width: 100,
    },
    {
      label: "Comment",
      field: "comment",
      sort: "asc",
      width: 100,
    },
    // {
    //   label: "Created By",
    //   field: "created_by",
    //   sort: "asc",
    //   width: 100,
    // },
  ];

  useEffect(() => {
    getConvertedLeads();
  }, []);

  const getFilteredConvertedLeads = async () => {
    try {
      setIsLoaded(true);
      let obj = {
        startDate,
        endDate,
      };
      if (userType === "admin") {
        obj = {
          ...obj,
          branch_code: branch,
        };
      }
      const response = await axios
        .post(`${url}/convertedLeads/filterConvertedByDate`, obj, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          console.log("data", data);
          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              phoneNumber : item.phone_number,
              phone_number : 
              typeof item.phone_number == "object" ? (item.phone_number):
              item.phone_number ? <>
              <span title="Click to copy" id={item.phone_number} className="clickToCopy" onClick={()=>{
          navigator.clipboard.writeText(item.phone_number);
          //window.alert("Phone Number Copied!")
          const element = document.querySelector('.isclickedText');  // Select the first element with class 'iscliacked'
          if (element) {
            element.textContent = 'Text Copied to Clipboard !';  // Modify the text inside xxtthe element
            element.style.padding = '10px';  // Modify the text inside xxtthe element
            
            setTimeout(() => {
              element.textContent = '';  // Modify the text inside the element
              element.style.padding = '0px';  // Modify the text inside xxtthe element              
            }, 1000);
          }
          }}
        >{item.phone_number}
        <ContentCopyIcon  style={{fontSize : "18px" , marginLeft : "5px" , color : "#d4d4d4"}}/>
        </span>
     
              </> : "",
            
              agent: item.agent ? item.agent.user_name : "",
              date: item.date ? item.date.split("T")[0] : "",
              time: item.date ? item.date.split("T")[1].split(".")[0] : "",
              //  + "-" + item.date.split("T")[1],
              created_by: item.created_by ? item.created_by.user_name : "",
              delivery_date: item.delivery_date
                ? item.delivery_date.split("T")[0]
                : "",
              action: (
                <>
                  <span
                    onClick={() =>
                      navigate(`/ConvertedLead/${item._id}_readOnly`)
                    }
                    style={{ color: `${lightBackground}`, cursor: "pointer" }}
                    title="view "
                  >
                    <RemoveRedEyeIcon />
                  </span>

                  <span
                    onClick={() =>
                      navigate(`/ConvertedLead/${item._id}_update`)
                    }
                    style={{
                      color: `${lightBackground}`,
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    title="edit"
                  >
                    <CreateIcon />
                  </span>

                  {!item.isTransfered ? (
                    <>
                      {" "}
                      <span
                        // prodctionlead/:id
                        onClick={() => {
                          if (
                            item.comment &&
                            item.customer_name &&
                            item.delivery_date &&
                            item.order_id &&
                            item.product_type
                          ) {
                            navigate(`/productionlead/${item._id}_CREATE`);
                          } else {
                            console.log("error");
                            setIsOpen(true);
                            setMessage("fill up the required fields");
                            setSeverity("warning");
                            setTimeout(() => {
                              setIsOpen(false);
                            }, 2000);
                          }
                        }}
                        style={{
                          color: `${lightBackground}`,
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        title="send to producton"
                      >
                        <KeyboardDoubleArrowRightIcon />
                      </span>
                    </>
                  ) : (
                    []
                  )}
                </>
              ),
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");
          setIsLoaded(false);
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
      ;
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };
  const getConvertedLeads = async () => {
    try {
      setIsLoaded(true);
      let data = {
        year: year.split("-")[0],
        month: year.split("-")[1],
      };
      const response = await axios
        .get(`${url}/convertedLeads/all`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          console.log("data", data);
          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              phoneNumber : item.phone_number,
              phone_number : 
              typeof item.phone_number == "object" ? (item.phone_number):
              item.phone_number ? <>
              <span title="Click to copy" id={item.phone_number} className="clickToCopy" onClick={()=>{
          navigator.clipboard.writeText(item.phone_number);
          //window.alert("Phone Number Copied!")
          const element = document.querySelector('.isclickedText');  // Select the first element with class 'iscliacked'
          if (element) {
            element.textContent = 'Text Copied to Clipboard !';  // Modify the text inside xxtthe element
            element.style.padding = '10px';  // Modify the text inside xxtthe element
            
            setTimeout(() => {
              element.textContent = '';  // Modify the text inside the element
              element.style.padding = '0px';  // Modify the text inside xxtthe element              
            }, 1000);
          }
          }}
        >{item.phone_number}
        <ContentCopyIcon  style={{fontSize : "18px" , marginLeft : "5px" , color : "#d4d4d4"}}/>
        </span>
     
              </> : "",
            
              agent: item.agent ? item.agent.user_name : "",
              date: item.date ? item.date.split("T")[0] : "",
              time: item.date ? item.date.split("T")[1].split(".")[0] : "",
              //  + "-" + item.date.split("T")[1],
              created_by: item.created_by ? item.created_by.user_name : "",
              delivery_date: item.delivery_date
                ? item.delivery_date.split("T")[0]
                : "",
              action: (
                <>
                  <span
                    onClick={() =>
                      navigate(`/ConvertedLead/${item._id}_readOnly`)
                    }
                    style={{ color: `${lightBackground}`, cursor: "pointer" }}
                    title="view "
                  >
                    <RemoveRedEyeIcon />
                  </span>
                  {!item.isTransfered || userType === "admin" ? (
                    <>
                      <span
                        onClick={() =>
                          navigate(`/ConvertedLead/${item._id}_update`)
                        }
                        style={{
                          color: `${lightBackground}`,
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                        title="edit"
                      >
                        <CreateIcon />
                      </span>

                      {!item.isTransfered ? (
                        <>
                          {" "}
                          <span
                            // prodctionlead/:id
                            onClick={() => {
                              if (
                                item.comment &&
                                item.customer_name &&
                                item.delivery_date &&
                                item.order_id &&
                                item.product_type
                              ) {
                                navigate(`/productionlead/${item._id}_CREATE`);
                              } else {
                                console.log("error");
                                setIsOpen(true);
                                setMessage("fill up the required fields");
                                setSeverity("warning");
                                setTimeout(() => {
                                  setIsOpen(false);
                                }, 2000);
                              }
                            }}
                            style={{
                              color: `${lightBackground}`,
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                            title="send to producton"
                          >
                            <KeyboardDoubleArrowRightIcon />
                          </span>
                        </>
                      ) : (
                        []
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ),
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");
          setIsLoaded(false);
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
      ;
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <>
      {" "}
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        <Paper fullWidth sx={{ m: 1, p: 2, maxWidth: "100%" }}>
          <Grid style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h5" color={`${lightText} `}>
              Converted Leads
            </Typography>
          </Grid>
          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              marginTop: "35px",
              marginBottom: "15px",
            }}
          >
            <Grid container md={6} xs={12}>
              <Grid container item md={3} xs={12} sx={{ p: 1 }}>
                <BasicDatePicker
                  label={"Start Date"}
                  value={startDate}
                  setValue={setStartDate}
                  item
                />
              </Grid>
              <Grid container item md={3} xs={12} sx={{ p: 1 }}>
                <BasicDatePicker
                  label={"End Date"}
                  value={endDate}
                  setValue={setEndDate}
                  item
                />
              </Grid>
              {userType === "admin" ? (
                <Grid item container md={3} xs={12} sx={{ p: 1 }}>
                  <FormControl margin="normal" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Branch
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={branch}
                      margin="normal"
                      label="Select Agent"
                      onChange={(e) => setBranch(e.target.value)}
                      disabled={isLoaded}
                    >
                      <MenuItem value={"KHI"}>Karachi</MenuItem>
                      <MenuItem value={"ISB"}>Islamabad</MenuItem>
                    </Select>
                  </FormControl>
              
                </Grid>
              ) : (
                []
              )}
              <Grid container item md={3} xs={12} sx={{ p: 3 }}>
                <Button
                  item
                  onClick={getFilteredConvertedLeads}
                  variant="contained"
                  fullWidth
                  style={{
                    background: `${lightBackground} `,
                    color: `${white} `,
                  }}
                >
                  {isLoaded ? (
                    <span>
                      {" "}
                      <CircularProgress
                        style={{
                          color: `${white}`,
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </span>
                  ) : (
                    <>
                      <RefreshIcon sx={{ mr: 1 }} />
                      Get Data
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>

            {/* stat */}
            <Grid
              container
              item
              md={6}
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
              sx={{ p: 3 }}
            >
              <Grid container item md={3} xs={12}>
                {userType === "admin" ? (
                  <Button
                    item
                    fullWidth
                    onClick={async () => {
                      setIsSheetLoaded(true);
                      await axios
                        .post(
                          `${url}/convertedLeads/downloadFile`,
                          {
                            link: url.split("/a")[0],
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${state.token}`,
                            },
                          }
                        )
                        .then((response) => {
                          console.log(response.data.data.data);
                          window.open(response.data.data.data, "_parent");
                        })
                        .catch((error) => {
                          if (error.response && error.response.status === 401) {
                            console.log("unauthorized error");
                            userUnAuthorized(dispatch);
                          } else {
                            console.log("network error");
                            setIsLoaded(false);
                            setIsOpen(true);
                            setMessage(error.message);
                            setSeverity("error");
                          }
                        });
                      
                      setIsSheetLoaded(false);
                    }}
                    variant="contained"
                    style={{
                      background: `${lightBackground}`,
                      color: `${white}`,
                    }}
                  >
                    {isSheetLoaded ? (
                      <span>
                        {" "}
                        <CircularProgress
                          style={{
                            color: `${white}`,
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </span>
                    ) : (
                      <>
                        <SimCardDownloadIcon sx={{ mr: 1 }} />
                        Excel Sheet
                      </>
                    )}
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
              <Grid container item sx={{ pl: 1 }} md={3} xs={12}>
                <Button
                  item
                  fullWidth
                  onClick={() => navigate(`/convertedlead/00000000_CREATE`)}
                  style={{
                    marginRight: "5px",
                    background: `${lightBackground}`,
                    color: `${white}`,
                  }}
                >
                  <AddCircleOutlineIcon sx={{ mr: 1 }} />
                  Add Existing Lead
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <DatatablePage data={data} isLoaded={isLoaded} />
        </Paper>
      </Container>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default ConvertedLeadsList;
