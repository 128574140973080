import React, { useState, useEffect } from 'react';
import { OTPtimer } from '../../baseUrl';

const Timer = ({isActive , setIsActive}) => {
  const [seconds, setSeconds] = useState(OTPtimer); // 5 minutes in seconds

  useEffect(() => {
    let timer;

    if (isActive && seconds > 0) {
      timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
        console.log("setInterval")
      }, 1000);
    }else if(isActive && seconds === 0){
        resetTimer();
    }

    return () => clearInterval(timer);
  }, [isActive, seconds]);

  const toggleTimer = () => {
    setIsActive(prevIsActive => !prevIsActive);
  };

  const resetTimer = () => {
    setIsActive(false);
    setSeconds(OTPtimer);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <div>
      <h5>{formatTime(seconds)}</h5>
      {/* <button onClick={toggleTimer}>{isActive ? 'Pause' : 'Start'}</button>
      <button onClick={resetTimer}>Reset</button> */}
    </div>
  );
};

export default Timer;
