import axios from "axios";
import React, { useEffect, useState } from "react";
import DatatablePage from "../../Components/DataTable/DataTable";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import { url } from "../../baseUrl";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { lightBackground, white } from "../../Assets/Theme/ThemeColors";
import { useDispatch, useSelector } from "react-redux";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreateIcon from "@mui/icons-material/Create";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import OnlineUsers from "../../Components/OnlineUsers/OnlineUsers";
import { userUnAuthorized } from "../../redux/actions/commonAction";

const UserSetup = () => {
  const [data, setData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [isModalopen, setIsModalopen] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user.data);
  const userslist = useSelector((state) => state.activeUsers.users);
  console.log("users list", userslist);
  // const navigate = useNavigate();

  const openModal = () => {
    setIsModalopen(true);
  };
  const closeModal = () => {
    setIsModalopen(false);
  };

  const columns = [
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 150,
    },
    {
      label: "User Name",
      field: "user_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "User Type",
      field: "user_type",
      sort: "asc",
      width: 270,
    },
    {
      label: "Email",
      field: "user_email",
      sort: "asc",
      width: 200,
    },
    {
      label: "Branch",
      field: "branch_code",
      sort: "asc",
      width: 200,
    },
    {
      label: "Account Status",
      field: "active",
      sort: "asc",
      width: 100,
    },
  ];

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      setIsLoaded(true);
      const response = await axios
        .get(`${url}/auth/getAllUsers`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              active: (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {item.active ? (
                  <>
                    {" "}
                    <span>
                      {" "}
                      <Typography
                        style={{
                          borderRadius: "5px",
                          background: "#74A662",
                          color: "white",
                          minWidth: "100px",
                        }}
                      >
                        active
                      </Typography>
                    </span>
                  </>
                  ) : (
                  <>
                    {" "}
                    <span>
                      {" "}
                      <Typography
                        style={{
                          borderRadius: "5px",
                          background: "#BB3F3F",
                          color: "white",
                          minWidth: "100px",
                        }}
                      >
                        In-active
                      </Typography>
                    </span>
                  </>
                  )}
                </div>
              ),
              action: (
                <>
                  <span
                    title="view"
                    onClick={() => navigate(`/user/${item._id}_readOnly`)}
                    style={{ color: `${lightBackground}`, cursor: "pointer" }}
                  >
                    <RemoveRedEyeIcon />
                  </span>

                  <span
                    onClick={() => navigate(`/user/${item._id}_update`)}
                    style={{
                      color: `${lightBackground}`,
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    title="edit"
                  >
                    <CreateIcon />
                  </span>
                </>
              ),
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");

          setIsLoaded(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <>
      {" "}
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        <Paper fullWidth sx={{ m: 1, p: 2, maxWidth: "100%" }}>
          <Grid
            xs={12}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography variant="h5">Users</Typography>
          </Grid>
          <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
            <Grid xs={12}>
              <Button
                onClick={openModal}
                variant="contained"
                style={{
                  background: `${lightBackground}`,
                  color: `${white}`,
                  marginRight: "10px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#4fdb04",
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                ></div>{" "}
                Online Users : {userslist?.length}
              </Button>
              <Button
                onClick={() => navigate(`/user/00000000000_CREATE`)}
                variant="contained"
                style={{ background: `${lightBackground}`, color: `${white}` }}
              >
                Add new user
              </Button>
            </Grid>
          </Grid>
          <DatatablePage data={data} isLoaded={isLoaded} />
        </Paper>
      </Container>
      <OnlineUsers
        open={isModalopen}
        handleClose={closeModal}
        data={userslist}
        btnMsg={"Close"}
      />
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default UserSetup;
