import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Drawer from "../Components/Drawer/Index";
import ConvertedLead from "./ConvertedLeads/ConvertedLead";
import ConvertedLeadsList from "./ConvertedLeads/ConvertedLeadsList";
import CreateCustomer from "./Customer/CreateCustomer";
import CustomerList from "./Customer/CustomerList";
import Dashboard from "./Dashboard/Dashboard";
import MainLeadsForm from "./MainLeads/MainLeadsForm";
import MainLeadsList from "./MainLeads/MainLeadsList";
import ProductionLead from "./Production/ProductionLead";
import ProductonList from "./Production/ProductionList";
import RawDataList from "./RawLeads/RawDataList";
import SignIn from "./SignIn/SignIn";
import AddUser from "./Users/AddUser";
import UserSetup from "./Users/UserSetup";
import MainSocket from "../Sockets/MainLeadsSocket/MainLeadsSocket";
import { developerToolsDisable } from "../Helpers/CommonFunctions";

const Routers = () => {
  const state = useSelector((state) => state?.user?.data?.success);
  const accessType = useSelector((state) =>
    state?.user?.data?.success ? state?.user?.data?.data?.user?.user_type : ""
  );
  return (
    <>
      <MainSocket />
      <BrowserRouter>
        <Routes>
          {/* <Route path="/verifyonetimepassword/:id" element={<OtpForm />} /> */}
          <Route
            path="/signin"
            element={
              state ? <Navigate to="/mainlead/list" replace /> : <SignIn />
            }
          />
          <Route
            exact
            path="/"
            element={state ? <Drawer /> : <Navigate to="/signin" replace />}
          >
            <Route
              path="/"
              element={
                accessType !== "production_agent" &&
                accessType !== "sales_agent" ? (
                  <Dashboard />
                ) : (
                  <Navigate to="/mainlead/list" replace />
                )
              }
            />

            <Route
              path="mainlead/list"
              element={
                accessType !== "production_agent" ? (
                  <MainLeadsList />
                ) : (
                  <Navigate to="/production/list" replace />
                )
              }
            />
            <Route
              path="mainlead/:id"
              element={
                accessType !== "production_agent" ? (
                  <MainLeadsForm />
                ) : (
                  <Navigate to="/production/list" replace />
                )
              }
            />

            <Route
              path="user/list"
              element={
                accessType === "admin" ? (
                  <UserSetup />
                ) : (
                  <Navigate to="/production/list" replace />
                )
              }
            />
            <Route
              path="user/:id"
              element={
                accessType === "admin" ? (
                  <AddUser />
                ) : (
                  <Navigate to="/production/list" replace />
                )
              }
            />

            <Route
              path="customer/list"
              element={
                accessType === "admin" ? (
                  <CustomerList />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="customer/:id"
              element={
                accessType === "admin" ? (
                  <CreateCustomer />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route
              path="convertedlead/list"
              element={
                accessType === "production_agent" ? (
                  <Navigate to="/" replace />
                ) : (
                  <ConvertedLeadsList />
                )
              }
            />
            <Route
              path="convertedlead/:id"
              element={
                accessType === "production_agent" ? (
                  <Navigate to="/" replace />
                ) : (
                  <ConvertedLead />
                )
              }
            />

            <Route path="production/list" element={<ProductonList />} />
            <Route path="productionlead/:id" element={<ProductionLead />} />

            <Route
              path="rawdata/list"
              element={
                accessType === "production_agent" ? (
                  <Navigate to="/" replace />
                ) : (
                  <RawDataList />
                )
              }
            />
            {/* <RawDataList />} /> */}
            <Route
              path="*"
              element={<Navigate to="/mainlead/list" replace />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routers;
