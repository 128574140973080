import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Typography } from "@mui/material";
import { Grid } from "antd";

export default function OnlineUsers({ open, handleClose, data, btnMsg }) {
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Online Users : {data?.length}</DialogTitle>
        <DialogContent style={{ minWidth: "55s0px" }}>
          <DialogContentText id="alert-dialog-description">
            <table className="OU-container">
              {/* <tr>
                <th></th>
                <th>User</th>
                <th>Email</th>
                <th>Branch</th>{" "}
              </tr> */}
              <tbody>
                {data?.map((user) => (
                  <tr>
                    {/* <Button sx={{ color: "#252625" }}> */}
                    <td>
                      <div>
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#4fdb04",
                            borderRadius: "50%",
                            marginRight: "5px",
                          }}
                        ></div>{" "}
                      </div>
                    </td>

                    <td>
                      {" "}
                      <Typography>{user?.user_name}</Typography>
                    </td>
                    <td>
                      {" "}
                      <Typography style={{ marginLeft: "20px" , display : "block"}}>
                        {user?.user_email}
                      </Typography>
                    </td>
                    <td>
                      {" "}
                      <Typography style={{ marginLeft: "20px" ,  }}>
                        {user?.branch_code}
                      </Typography>
                    </td>
                    {/* </Button> */}
                    
                  </tr>
                ))}
              </tbody>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ background: "#ffcd24", color: "black" }}
            onClick={handleClose}
          >
            {btnMsg}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
