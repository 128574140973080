import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { lightBackground, white } from "../../Assets/Theme/ThemeColors";

export default function SessionExpired({
  open,
  handleClose : handleYes,
  handleNo,
  msgContent,
  btnMsg,
  closebtn,
  title
}) {
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgContent}{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {closebtn !== null && closebtn?.length > 0 ? (
            <Button
            type="clear"
            style={{
              border: `1px solid ${lightBackground}`,
              color: `${lightBackground}`,
              background: `${white}`,
            }}
            variant="outlined"
            margin="normal"
              onClick={handleNo}
            >
              {closebtn}
            </Button>
          ) : (
            ""
          )}
          <Button
             variant="contained"
             style={{
               background: `${lightBackground}`,
               color: `${white}`,
             }}
             margin="normal"
            onClick={handleYes}
          >
            {btnMsg}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
