export const reloadList = (dispatch) => {
  try {
    return dispatch({
      type: "RELOADLIST",
      payload: true,
    });
  } catch (error) {
    console.log(error);
  }
};

export const clearReloadList = (dispatch) => {
  try {
    return dispatch({
      type: "CLEARRELOADLIST",
      payload: false,
    });
  } catch (error) {
    console.log(error);
  }
};
export const userConnected = (dispatch) => {
  try {
    return dispatch({
      type: "CONNECTED",
      payload: true,
    });
  } catch (error) {
    console.log(error);
  }
};
export const userDisConnected = (dispatch) => {
  try {
    return dispatch({
      type: "DISCONNECTED",
      payload: false,
    });
  } catch (error) {
    console.log(error);
  }
};
export const userAuthorized = (dispatch) => {
  try {
    return dispatch({
      type: "AUTHORIZED",
      payload: true,
    });
  } catch (error) {
    console.log(error);
  }
};
export const userUnAuthorized = (dispatch) => {
  try {
    return dispatch({
      type: "UNAUTHORIZED",
      payload: false,
    });
  } catch (error) {
    console.log(error);
  }
};
