import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../Assets/images/mozacklogoWhite.png";
import Timer from "../../Components/Timer/Timer";
import SessionExpired from "../../Components/sessionExpire/SessionExpired";
import { useNavigate, useParams } from "react-router-dom";
import { OTPtimer, url } from "../../baseUrl";
import axios from "axios";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import { login } from "../../redux/actions/authAction";
import { useDispatch } from "react-redux";
import { userAuthorized, userUnAuthorized } from "../../redux/actions/commonAction";

const OtpForm = ({ userId, setUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { id } = useParams();
  const [isActiveTimer, setIsActiveTimer] = useState(true);

  const [isClicked, setIsClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [otpcode, setOtpcode] = useState();

  const handleIsActiveTimer = (value) => {
    setIsActiveTimer(value);
  };
  const handleCloseDialog = () => {
    setIsActiveTimer(true);
    setUser(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsClicked(true);
    if (userId && otpcode) {
      const postData = {
        userId: userId,
        otp: otpcode,
      };
      await axios
        .post(`${url}/OTP/verify`, postData)
        .then((data) => {
          if (data.data.data.success) {
            login(data.data.data, dispatch);
            userAuthorized(dispatch);
            setIsOpen(true);
            setMessage(data.data.data.message);
            setSeverity("success");

            setTimeout(() => {
              setIsOpen(false);
              setIsClicked(false);
              navigate("/");
            }, 3000);
          } else {
            setIsClicked(false);
            setIsOpen(true);
            setMessage(data.data.data.message);
            setSeverity("error");
            setTimeout(() => {
              setIsOpen(false);
            }, 3000);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            //   setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
            setTimeout(() => {
              setIsOpen(false);
            }, 3000);
          }
        });
    } else {
      setIsClicked(false);
      setIsOpen(true);
      setMessage("invalid code !");
      setSeverity("error");
      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    }
    //  const data = new FormData(event.currentTarget);
  };

  return (
    <Grid xs={12} container>
      <Grid
        container
        item
        xs={12}
        sx={{
          height: "10vh",
          background: "black",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          md={4}
          xs={8}
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12}>
            <img item src={logo} height={30} width={200} alt="logo" />
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button title="close" onClick={() => setUser(null)}>
            {" "}
            <CloseIcon sx={{ fontSize: "30px", color: "white" }} />
          </Button>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Grid item md={4} xs={10}>
          <Grid sx={{ p: 2 }}>
            {" "}
            <LockOpenIcon sx={{ fontSize: "50px" }} />
          </Grid>
          <Grid sx={{ p: 2 }}>
            <Typography variant="h4">We just send you an email.</Typography>
          </Grid>
          <Grid sx={{ p: 2 }}>
            <Typography>
              To log in , Enter the OTP code we have just sent on your email***.{" "}
            </Typography>
            <Typography>It will expire in {OTPtimer / 60} mins.</Typography>
          </Grid>
          <Grid
            container
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TextField
              item
              xs={6}
              md={6}
              sx={{ width: "100%" }}
              label="Your 6 digit code "
              value={otpcode}
              inputProps={{ maxLength: 6 }} // Set the maximum length
              onChange={(event) => {
                setOtpcode(event.target.value);
              }}
            />
          </Grid>
          <Grid sx={{ p: 2 }}>
            <Button
              variant="contained"
              sx={{
                minWidth: "200px",
                maxWidth: "500px",
                background: "#ffcd24",
                color: "black",
                width: "100%",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
          <Grid
            sx={{ display: "flex", flexFlow: "row", justifyContent: "center" }}
          >
            <Typography
              onClick={() => setUser(null)}
              sx={{ cursor: "pointer", textDecoration: "underLine", mr: 2 }}
            >
              I didn't recieve a code{" "}
            </Typography>
            <Timer setIsActive={handleIsActiveTimer} isActive={isActiveTimer} />
          </Grid>
        </Grid>
      </Grid>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
      <SessionExpired
        open={!isActiveTimer}
        handleClose={handleCloseDialog}
        msgContent={"The OTP has expired. Please log in again."}
        btnMsg={"Login"}
      />
    </Grid>
  );
};

export default OtpForm;
