import "./App.css";
import Routers from "./Pages/Routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { Notifications } from 'react-push-notification';
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { useEffect } from "react";
import { appBuildVersion } from "./baseUrl";
const version = process.env.REACT_APP_APP_BUILD_VERSION;


function App() {
  //disabling consoles
  console.log = () => { };

  // console.log("APP_BUILD_VERSION" , version)

  useEffect(() => {
    if(version !== appBuildVersion){
      localStorage.clear();
      console.log("new build");
    }
  }, [])

 
  return (
    // <ThemeProvider theme={primaryTheme}>
    <div className="App">
      <Notifications />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Routers />
        </PersistGate>
      </Provider>
    </div>
    // </ThemeProvider >
  );
}

export default App;
