var users = [];

const ActiveUsersReducer = (state = users, action) => {
  var { type, payload } = action;

  switch (type) {
    case "ADDUSERS":
      return {
        ...state,
        users: payload,
      };
    case "CLEARUSERS":
      return {
        ...state,
        users: [],
      };

    default:
      return state;
  }
};

export default ActiveUsersReducer;
