export const header = "#000000";
export const navbar = "#000000";
export const lightText = "#4f4c4c";
export const darkText = "#000000";
export const lightButton = "#eeecff";
export const darkButton = "#ffcd24 ";
export const dimBackground = "#e6e3e3";
export const lightBackground = "#403e3e";
export const darkBackground = "#303030";
export const white = "#ffffff";
export const yellowGold = "#ffc800";

