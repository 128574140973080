import React, { useState, useCallback } from "react";
import _ from "lodash";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import "./DynamicSearchInput.css";
import { FaSearch, FaTimes } from 'react-icons/fa'; // Import necessary icons


const DynamicSearchInput = ({ handleSearchBar, handleScreenSearch}) => {
  const [query, setQuery] = useState("");

  // Create a debounced function for handling the search input
  const debouncedSearch = useCallback(
    _.debounce((searchTerm) => {
      // Perform the search operation here
      console.log("Searching for:", searchTerm);
      // handleSearchBar(searchTerm);
      // handleScreenSearch(searchTerm , filtData);

      // You can call an API or perform other operations here
    }, 300),
    [] // Ensure the debounce function is not recreated on every render
  );

  const handleSearchBtn = () => {
    handleSearchBar(query);
  };
  const clearSearchInput = () => {
    setQuery("");
    handleSearchBar("");
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    // debouncedSearch(value);
    handleScreenSearch( value)
  };

  return (
    <div className="search-container">
      <input
        className="search-input"
        type="text"
        value={query}
        onChange={handleChange}
        placeholder="Search..."
      />
      {query.trim() !== "" && query !== null ? (
        <>
          <span className="cancelIcon" title="Clear">
          <FaTimes onClick={clearSearchInput} />
          </span>
          <span className="searchIcon" title="Search">
          <FaSearch onClick={handleSearchBtn} />
          </span>
        </>
      ) : (
        []
      )}
    </div>
  );
};

export default DynamicSearchInput;
