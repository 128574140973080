var initialState = {
  noti: [],
};

const NotificationReducer = (state = initialState, action) => {
  var { type, payload } = action;

  switch (type) {
    case "ADDNOTE":
      return {
        ...state,
        noti: [...state.noti, payload],
      };
    case "CLEARNOTE":
      return {
        ...state,
        noti: [],
      };

    default:
      return state;
  }
};

export default NotificationReducer;
