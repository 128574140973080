export const addNotification = (body, dispatch) => {
    try {
        return dispatch({
            type: "ADDNOTE",
            payload: body
        })
    } catch (error) {
        console.log(error)
    }
}

export const clearNotification = (dispatch) => {
    try {
        return dispatch({
            type: "CLEARNOTE",
            payload: []
        })
    } catch (error) {
        console.log(error)
    }
}