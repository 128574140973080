var initialState = {
  isNewLead: false,
  isConnected: false,
  isAuthorized: false,
};

const CommonReducer = (state = initialState, action) => {
  var { type } = action;

  switch (type) {
    case "RELOADLIST":
      return {
        ...state,
        isNewLead: true,
      };
    case "CLEARRELOADLIST":
      return {
        ...state,
        isNewLead: false,
      };
    case "CONNECTED":
      return {
        ...state,
        isConnected: true,
      };
    case "DISCONNECTED":
      return {
        ...state,
        isConnected: false,
      };
    case "AUTHORIZED":
      return {
        ...state,
        isAuthorized: true,
      };
    case "UNAUTHORIZED":
      return {
        ...state,
        isAuthorized: false,
      };

    default:
      return state;
  }
};

export default CommonReducer;
