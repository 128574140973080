import {
  Alert,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Box, width } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  lightBackground,
  lightButton,
  navbar,
  white,
} from "../../Assets/Theme/ThemeColors";
import { url } from "../../baseUrl";
import FormDialog from "../../Components/AddMessage/AddMessageDialog";
import BasicTable from "../../Components/BasicTable/BasicTable";
import BasicDatePicker from "../../Components/DatePicker/DatePicker";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import { userUnAuthorized } from "../../redux/actions/commonAction";

const CreateCustomer = () => {
  let { id } = useParams();
  let param = id.split("_");
  let leadId = param[0];
  var accessType = param[param.length - 1];

  const state = useSelector((state) => state.user.data);
  const userType = state.data.user.user_type;
  console.log("state", userType);

  const dispatch = useDispatch();

  const [formData, setFromData] = useState({});
  const [name, setName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [priority, setPriority] = useState("");
  const [wStatus, setWStatus] = useState("");
  const [wName, setWName] = useState("");
  const [oStatus, setOStatus] = useState("");
  const [pType, setPType] = useState("");
  const [words, setWords] = useState("");
  const [comment, setComment] = useState("");
  const [wComment, setWComment] = useState("");
  const [updateDisabled, setUpdateDisabled] = useState(false);
  const [disabledAll, setDisabledAll] = useState(false);

  const [nameData, setNameData] = useState("");
  const [tableData, setTableData] = useState();

  const [openNewMsg, setOpenNewMsg] = React.useState(false);
  
  const [branch, setBranch] = useState("");
  //notify states
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  //  const [isLoaded, setIsLoaded] = useState(false);

  const handleClickOpen = () => {
    setOpenNewMsg(true);
  };
 
  const handleClose = () => {
    findSpecific();
    setOpenNewMsg(false);
  };

  const [isTableLoaded, setIsTableLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // var tableData = {};

  const columns = [
    {
      id: 1,
      name: "Date",
    },
    {
      id: 2,
      name: "Message by",
    },
    {
      id: 3,
      name: "Status",
    },
    {
      id: 4,
      name: "Message",
    },
    {
      id: 5,
      name: "Attachment",
    },
  ];

  const navigate = useNavigate();
  console.log(leadId, accessType);

  useEffect(() => {
    getSpecific();
  }, []);

  const getSpecific = async () => {
    if (accessType !== "CREATE") {
      const response = await axios
        .post(
          `${url}${
            accessType === "readOnly"
              ? "/productionLeads/getSpecific"
              : "/productionLeads/getSpecific"
          }`,
          { _id: leadId },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((response) => {
          console.log("view lead prod", response.data.data.data);
        
          setFromData(response.data.data.data);
          if (accessType === "update") {
            userType === "admin"
              ? setUpdateDisabled(false)
              : setUpdateDisabled(true);
          } else {
            setDisabledAll(true);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
      
    } else {
      const response = await axios
        .post(
          `${url}${"/convertedLeads/getSpecific"}`,
          { _id: leadId },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((response) => {
          setFromData(response.data.data.data);
          userType === "admin"
            ? setUpdateDisabled(false)
            : setUpdateDisabled(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
      
    }
  };

  useEffect(() => {
    findSpecific();
  }, []);

  const findSpecific = async () => {
    setIsTableLoaded(false);
    await axios
      .post(
        `${url}/commments/findSpecific`,
        { _id: leadId },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then(async (response) => {
        console.log("response chat", response.data.data);
        let obj = {
          columns: columns,
          rows: response.data.data.data,
        };
        setTableData(obj);
        setTimeout(() => {
          setIsTableLoaded(true);
          console.log("Chat array ", tableData);
        }, 3000);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("unauthorized error");
          userUnAuthorized(dispatch);
        } else {
          console.log("network error");
          setIsLoaded(false);
          setIsOpen(true);
          setMessage(error.message);
          setSeverity("error");
        }
      });
    
  };

  useEffect(() => {
    if (formData) {
      setName(formData.customer_name);
      setOrderId(formData.order_id);
      setDeliveryDate(
        formData.delivery_date ? formData.delivery_date.split("T")[0] : ""
      );
      setPriority(formData.priority);
      setWStatus(formData.writer_status);
      setWName(formData.writer_name);
      setOStatus(formData.order_status);
      setPType(formData.product_type);
      setWords(formData.word_count);
      setComment(formData.comment);
      setWComment(formData.writer_comment);
      setBranch(formData.branch_code);
     
    }
  }, [formData]);

  const submitForm = async () => {
    setIsLoaded(true);
    const data =
      accessType === "CREATE"
        ? {
            date: new Date(),
            customer_name: name,
            order_id: orderId,
            delivery_date: deliveryDate,
            priority,
            writer_status: wStatus,
            writer_name: wName,
            order_status: oStatus,
            product_type: pType,
            word_count: words,
            comment,
            writer_comment: wComment,
            parentId: leadId,
            branch_code : branch
       
          }
        : {
            date: new Date(),
            customer_name: name,
            order_id: orderId,
            delivery_date: deliveryDate,
            priority,
            writer_status: wStatus,
            writer_name: wName,
            order_status: oStatus,
            product_type: pType,
            word_count: words,
            comment,
            writer_comment: wComment,
            _id: leadId,
          };
    await axios
      .post(
        `${url}${
          accessType === "CREATE"
            ? "/productionLeads/addNew"
            : "/productionLeads/edit"
        }`,
        data,
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then((response) => {
        setIsLoaded(false);
        if (response.data.data.success) {
          console.log(response.data.data.data);
          accessType = "readOnly";

          let id = response.data.data.data._id;

          // navigate(`/productionlead/${id}_readOnly`);
          console.log("true");
          setIsOpen(true);
          setMessage("Record Saved!");
          setSeverity("success");
          setTimeout(() => {
            navigate("/production/list");
          }, 2000);
          // setIsLoaded(false);
        } else {
          setIsOpen(true);
          setMessage(response.data.data.message);
          setSeverity("error");
          console.log(response.data.message);
        }

        console.log(data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("unauthorized error");
          userUnAuthorized(dispatch);
        } else {
          console.log("network error");
          setIsLoaded(false);
          setIsOpen(true);
          setMessage(error.message);
          setSeverity("error");
        }
      });
    
    setTimeout(() => {
      setIsOpen(false);
      setIsLoaded(false);
    }, 2000);
  };

  return (
    <>
      {" "}
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        <Box sx={{ mt: 3 }}>
          <Paper sx={{ p: 3, mr: 2, ml: 2 }}>
            <Grid container sx={{ mt: 2, p: 3 }}>
              <Typography variant="h5" color="Primary">
                Production Lead
              </Typography>
            </Grid>
            <Grid item className="Row" sx={{ p: 1 }}>
              <Grid item container md={9} xs={12}>
                <TextField
                  name="date"
                  label="Date"
                  // type="date"
                  margin="normal"
                  disabled="true"
                  value={`${
                    new Date().getMonth() +
                    1 +
                    " / " +
                    new Date().getDate() +
                    " / " +
                    new Date().getFullYear()
                  }`}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="clientName"
                  label="Customer Name"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={name}
                  disabled={updateDisabled || disabledAll}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="orderId"
                  label="Order Id"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={orderId}
                  disabled={updateDisabled || disabledAll}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="deliveryDate"
                  label="Delivery Date"
                  type="date"
                  margin="normal"
                  fullWidth
                  value={deliveryDate}
                  disabled={disabledAll}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Priority
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={priority}
                    margin="normal"
                    label="Priority"
                    style={{ textAlign: "left" }}
                    onChange={(e) => setPriority(e.target.value)}
                    disabled={disabledAll}
                  >
                    <MenuItem value={"High"}>High</MenuItem>
                    <MenuItem value={"Medium"}>Medium</MenuItem>
                    <MenuItem value={"Urgent"}>Urgent</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Writer Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={wStatus}
                    margin="normal"
                    style={{ textAlign: "left" }}
                    label="Writer Status"
                    onChange={(e) => setWStatus(e.target.value)}
                    disabled={disabledAll}
                  >
                    <MenuItem value={"Revision"}>Revision</MenuItem>
                    <MenuItem value={"Working"}>Working</MenuItem>
                    <MenuItem value={"Completed"}>Completed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="platform"
                  label="Writer Name"
                  type="text"
                  style={{ textAlign: "left" }}
                  margin="normal"
                  fullWidth
                  value={wName}
                  onChange={(e) => setWName(e.target.value)}
                  disabled={disabledAll}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Order Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={oStatus}
                    style={{ textAlign: "left" }}
                    margin="normal"
                    label="Order Status"
                    onChange={(e) => setOStatus(e.target.value)}
                    disabled={disabledAll}
                  >
                    <MenuItem value={"NewOrder"}>New Order</MenuItem>
                    <MenuItem value={"Revision"}>Revision</MenuItem>
                    <MenuItem value={"Delivered"}>Delivered</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="product_service"
                  label="Production / Service"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={pType}
                  onChange={(e) => setPType(e.target.value)}
                  disabled={updateDisabled || disabledAll}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="noOfWords"
                  label="No Of Words"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={words}
                  disabled={updateDisabled || disabledAll}
                />
              </Grid>
              <Grid item container md={12} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="Comemnt"
                  label="Comment"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={comment}
                  disabled={updateDisabled || disabledAll}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={12} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="writer_comment"
                  label="Writer Comment"
                  multiline
                  rows={4}
                  type="text"
                  margin="normal"
                  fullWidth
                  value={wComment}
                  onChange={(e) => setWComment(e.target.value)}
                  disabled={disabledAll}
                />
              </Grid>
            </Grid>
            {accessType === "readOnly" ? (
              <Grid
                container
                className="Row"
                style={{
                  // background: `${lightBackground}`,
                  padding: "5px",
                }}
              >
                <Grid md={10} xs={12} sx={{ p: 1 }}>
                  <Typography variant="h5" style={{ color: `${navbar}` }}>
                    Lead Messages
                  </Typography>
                </Grid>
                <Grid md={2} xs={12} sx={{ p: 1 }}>
                  <Button
                    variant="contained"
                    style={{
                      background: `${lightBackground}`,
                      color: `${white}`,
                    }}
                    margin="normal"
                    fullWidth
                    onClick={handleClickOpen}
                  >
                    Add New Message
                  </Button>
                </Grid>

                {isTableLoaded ? (
                  <BasicTable tableData={tableData} />
                ) : (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      style={{
                        color: `${lightBackground}`,
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  </span>
                )}
              </Grid>
            ) : (
              []
            )}

            <Grid
              container
              className="Row"
              sx={{ mt: 2 }}
              style={{
                gap: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item container md={1} xs={1}>
                <Button
                  type="clear"
                  style={{
                    border: `1px solid ${lightBackground}`,
                    color: `${lightBackground}`,
                    background: `${white}`,
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  onClick={() => {
                    navigate("/production/list");
                  }}
                >
                  back
                </Button>
              </Grid>
              {accessType !== "readOnly" ? (
                <Grid item container md={1} xs={1}>
                  <Button
                    onClick={submitForm}
                    variant="contained"
                    style={{
                      background: `${lightBackground}`,
                      color: `${white}`,
                    }}
                    margin="normal"
                    fullWidth
                    disabled={isLoaded || accessType === "readOnly"}
                  >
                    {isLoaded ? (
                      <span>
                        {" "}
                        <CircularProgress
                          style={{
                            color: `${white}`,
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </span>
                    ) : accessType === "CREATE" ? (
                      "save"
                    ) : (
                      "UPDATE"
                    )}
                  </Button>
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Paper>
        </Box>
        <FormDialog
          leadId={leadId}
          open={openNewMsg}
          handleClose={handleClose}
        />
      </Container>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default CreateCustomer;
