import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../baseUrl";
import DatatablePage from "../../Components/DataTable/DataTable";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreateIcon from "@mui/icons-material/Create";
import RefreshIcon from "@mui/icons-material/Refresh";

import {
  darkButton,
  lightBackground,
  lightText,
  white,
} from "../../Assets/Theme/ThemeColors";
import { useDispatch, useSelector } from "react-redux";
import BasicDatePicker from "../../Components/DatePicker/DatePicker";
import { userUnAuthorized } from "../../redux/actions/commonAction";

const ProductonList = () => {
  const state = useSelector((state) => state.user.data);
  const userType = state.data.user.user_type;
  console.log(state.data.user.user_type);

  const [startDate, setStartDate] = useState(
    new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-2`)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 30)
  );

  const [branch, setBranch] = useState("KHI");

  const [data, setData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 100,
    },
    {
      label: "Order Id",
      field: "order_id",
      sort: "asc",
      width: 100,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 270,
    },
    {
      label: "Time",
      field: "time",
      sort: "asc",
      width: 270,
    },
    {
      label: "Client Name",
      field: "customer_name",
      sort: "asc",
      width: 200,
    },

    {
      label: "Priority",
      field: "priority",
      sort: "asc",
      width: 200,
    },

    {
      label: "Writer Status",
      field: "writer_status",
      sort: "asc",
      width: 200,
    },
    {
      label: "Writer Name",
      field: "writer_name",
      sort: "asc",
      width: 300,
    },
    {
      label: "Order Status",
      field: "order_status",
      sort: "asc",
      width: 300,
    },
    {
      label: "Delivery Date",
      field: "delivery_date",
      sort: "asc",
      width: 250,
    },
    {
      label: "Production Type",
      field: "product_type",
      sort: "asc",
      width: 100,
    },
    {
      label: "No Of Words",
      field: "word_count",
      sort: "asc",
      width: 200,
    },
    {
      label: "Comment",
      field: "comment",
      sort: "asc",
      width: 200,
    },
    {
      label: "Writer Comment",
      field: "writer_comment",
      sort: "asc",
      width: 300,
    },
    {
      label: "Created By",
      field: "created_by",
      sort: "asc",
      width: 200,
    },
  ];

  useEffect(() => {
    getProductionLeads();
  }, []);

  const getFilteredProductionLeads = async () => {
    try {
      setIsLoaded(true);
      let obj = {
        startDate,
        endDate,
      };
      if (userType === "admin") {
        obj = {
          ...obj,
          branch_code: branch,
        };
      }
      const response = await axios
        .post(`${url}/productionLeads/filterProductionByDate`, obj, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              agentName: item.agent_name ? item.agent_name.user_name : "",
              date: item.date.split("T")[0],
              time: item.date.split("T")[1].split(".")[0],
              delivery_date: item.delivery_date.split("T")[0],
              created_by: item.created_by ? item.created_by.user_name : "",
              order_id: item.order_id,
              writer_status: item.writer_status ? (
                <>
                  <span>
                    <Typography
                      style={{
                        borderRadius: "5px",
                        padding: "5px",
                        background: `${
                          item.writer_status === "Working"
                            ? "yellow"
                            : item.writer_status === "Completed"
                            ? "green"
                            : "red"
                        }`,
                        color: `${
                          item.writer_status === "Working" ? "black" : "white"
                        }`,
                      }}
                    >
                      {item.writer_status}
                    </Typography>
                  </span>
                </>
              ) : (
                ""
              ),
              action: (
                <>
                  <span
                    onClick={() =>
                      navigate(`/productionlead/${item._id}_readOnly`)
                    }
                    title="view"
                    style={{ color: `${lightBackground}`, cursor: "pointer" }}
                  >
                    <RemoveRedEyeIcon />
                  </span>

                  <span
                    onClick={() =>
                      navigate(`/productionlead/${item._id}_update`)
                    }
                    style={{
                      color: `${lightBackground}`,
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    title="edit"
                  >
                    <CreateIcon />
                  </span>
                </>
              ),
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");
          setIsLoaded(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
      
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };
  const getProductionLeads = async () => {
    try {
      setIsLoaded(true);
      const response = await axios
        .get(`${url}/productionLeads/all`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              agentName: item.agent_name ? item.agent_name.user_name : "",
              date: item.date.split("T")[0],
              time: item.date.split("T")[1].split(".")[0],
              delivery_date: item.delivery_date.split("T")[0],
              created_by: item.created_by ? item.created_by.user_name : "",
              order_id: item.order_id,
              writer_status: item.writer_status ? (
                <>
                  <span>
                    <Typography
                      style={{
                        borderRadius: "5px",
                        padding: "5px",
                        background: `${
                          item.writer_status === "Working"
                            ? "yellow"
                            : item.writer_status === "Completed"
                            ? "green"
                            : "red"
                        }`,
                        color: `${
                          item.writer_status === "Working" ? "black" : "white"
                        }`,
                      }}
                    >
                      {item.writer_status}
                    </Typography>
                  </span>
                </>
              ) : (
                ""
              ),
              action: (
                <>
                  <span
                    onClick={() =>
                      navigate(`/productionlead/${item._id}_readOnly`)
                    }
                    title="view"
                    style={{ color: `${lightBackground}`, cursor: "pointer" }}
                  >
                    <RemoveRedEyeIcon />
                  </span>

                  <span
                    onClick={() =>
                      navigate(`/productionlead/${item._id}_update`)
                    }
                    style={{
                      color: `${lightBackground}`,
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    title="edit"
                  >
                    <CreateIcon />
                  </span>
                </>
              ),
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");
          setIsLoaded(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
      
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <>
      {" "}
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        <Paper fullWidth sx={{ m: 1, p: 2, maxWidth: "100%" }}>
          <Grid style={{ display: "flex", justifyContent: "flex-start" }}>
            <Typography variant="h5" color={`${lightText} `}>
              Production Leads
            </Typography>
          </Grid>
          <Grid
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "40px",
              paddingBottom: "20px",
            }}
          >
            <Grid container md={6} xs={12}>
              <Grid container item md={3} xs={12} sx={{ p: 1 }}>
                <BasicDatePicker
                  label={"Start Date"}
                  value={startDate}
                  setValue={setStartDate}
                  item
                />
              </Grid>
              <Grid container item md={3} xs={12} sx={{ p: 1 }}>
                <BasicDatePicker
                  label={"End Date"}
                  value={endDate}
                  setValue={setEndDate}
                  item
                />
              </Grid>
              {userType === "admin" ? (
                <Grid item container md={3} xs={12} sx={{ p: 1 }}>
                  <FormControl margin="normal" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Branch
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={branch}
                      margin="normal"
                      label="Select Agent"
                      onChange={(e) => setBranch(e.target.value)}
                      disabled={isLoaded}
                    >
                      <MenuItem value={"KHI"}>Karachi</MenuItem>
                      <MenuItem value={"ISB"}>Islamabad</MenuItem>
                    </Select>
                  </FormControl>
              
                </Grid>
              ) : (
                []
              )}
              <Grid container item md={3} xs={12} sx={{ p: 3 }}>
                <Button
                  item
                  onClick={getFilteredProductionLeads}
                  variant="contained"
                  fullWidth
                  style={{
                    background: `${lightBackground} `,
                    color: `${white} `,
                  }}
                >
                  {isLoaded ? (
                    <span>
                      {" "}
                      <CircularProgress
                        style={{
                          color: `${white}`,
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </span>
                  ) : (
                    <>
                      <RefreshIcon sx={{ mr: 1 }} />
                      Get Data
                    </>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <DatatablePage data={data} isLoaded={isLoaded} />
        </Paper>
      </Container>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default ProductonList;
