export const addUsers = (body, dispatch) => {
    try {
        return dispatch({
            type: "ADDUSERS",
            payload: body
        })
    } catch (error) {
        console.log(error)
    }
}

export const clearUsers = (dispatch) => {
    try {
        return dispatch({
            type: "CLEARUSERS",
            payload: []
        })
    } catch (error) {
        console.log(error)
    }
}