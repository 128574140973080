import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../../baseUrl";
import DatatablePage from "../../Components/DataTable/DataTable";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreateIcon from "@mui/icons-material/Create";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  lightBackground,
  lightText,
  white,
} from "../../Assets/Theme/ThemeColors";
import { useDispatch, useSelector } from "react-redux";
import BasicDatePicker from "../../Components/DatePicker/DatePicker";
import { userUnAuthorized } from "../../redux/actions/commonAction";
import SessionExpired from "../../Components/sessionExpire/SessionExpired";
import DynamicSearchTable from "../../Components/DynamicSearchTable/DynamicSearchTable";
import Pagination from "../../Components/Pagination/Pagination";
import DynamicSearchInput from "../../Components/DynamicSearchInput/DynamicSearchInput";

const MainLeadsList = () => {
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [startDate, setStartDate] = useState(
    new Date(`${new Date().getFullYear()}-${new Date().getMonth()}-1`)
  );
  // const [endDate, setEndDate] = useState(
  //   new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  // );
  const setEndDateValue = () => {
    //`${new Date().getFullYear()}-${new Date().getMonth()  +2}-1`
    let date;
    let year = new Date().getFullYear() ;
    let month = new Date().getMonth()+1 ;
    
    if(month<10){
      date = `${year}-${month+1}-1`
    }else if(month === 11){
      date = `${year+1}-${1}-1`
    }else{
      date = `${year+1}-${2}-1`
    }
    console.log("date : " , date)
    return date
  } 
 
  const [endDate, setEndDate] = useState(new Date(setEndDateValue()));

  const [pagination, setPagination] = useState({
    limit: sessionStorage.getItem("limit")
      ? sessionStorage.getItem("limit")
      : 10,
    text: "",
    page: 1,
    currentPage: 1,
    totalRecords: 100,
    totalPages: 10,
    buttonsCount: 4,
    buttonStart: 1,
    buttonEnd: 4,
  });

  const handlePageData = (data) => {
    setPagination(data);
  };
  const handleSearchBar = (text = "") => {
    pagination.text = text;
    getAllMainLeads(1);
  };

  const filterDataArray = (queryText, array) => {
    if (!queryText) return array;

    const lowerCaseQuery = queryText.toLowerCase();

    let tempArr = array.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(lowerCaseQuery)
      )
    );
    return setTableData(tempArr);
  };

  // const clearSearchInput = () => {
  //   setPagination({
  //     ...pagination,
  //     text: "",
  //   });
  // };

  const [isSheetLoaded, setIsSheetLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [branch, setBranch] = useState("ALL-Br");
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [deltIsClicked, setDeltIsClicked] = useState(false);
  const [deltId, setDeltId] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  
  const handleConfirmClose = () => {
    setDeltId("id");
    setConfirmIsOpen(false);
  };
  const handleConfirmYes = (id) => {
    deleteLead(id);
    setConfirmIsOpen(false);
  };
  const handleConfirmOpen = (id) => {
    setDeltId(id);
    setConfirmIsOpen(true);
  };

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.user.data);
  const userType = state.data.user.user_type;
  const isNewLead = useSelector((state) => state?.common?.isNewLead);

  const columns =
    userType === "admin"
      ? [
          {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 200,
          },
          {
            label: "Month",
            field: "month",
            sort: "asc",
            width: 150,
          },
          {
            label: "Date",
            field: "date",
            sort: "asc",
            width: 270,
          },
          {
            label: "Time",
            field: "time",
            sort: "asc",
            width: 270,
          },
          {
            label: "Client Name",
            field: "client_name",
            sort: "asc",
            width: 200,
          },
          {
            label: "Email Address",
            field: "email",
            sort: "asc",
            width: 100,
          },
          {
            label: "Phone Number",
            field: "phone_number",
            sort: "asc",
            width: 150,
          },
          {
            label: "Brand",
            field: "brand",
            sort: "asc",
            width: 100,
          },

          {
            label: "Agent Name",
            field: "agentName",
            sort: "asc",
            width: 100,
          },
          {
            label: "Service",
            field: "chooseService",
            sort: "asc",
            width: 100,
          },
          {
            label: "Platform",
            field: "platform",
            sort: "asc",
            width: 100,
          },
          {
            label: "Payment Status",
            field: "payment_status",
            sort: "asc",
            width: 100,
          },
          {
            label: "Calling Comments",
            field: "calling_comment",
            sort: "asc",
            width: 100,
          },
          {
            label: "Comments",
            field: "comment",
            sort: "asc",
            width: 100,
          },
        ]
      : [
          {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 200,
          },
          {
            label: "Month",
            field: "month",
            sort: "asc",
            width: 150,
          },
          {
            label: "Date",
            field: "date",
            sort: "asc",
            width: 270,
          },
          {
            label: "Time",
            field: "time",
            sort: "asc",
            width: 270,
          },
          {
            label: "Client Name",
            field: "client_name",
            sort: "asc",
            width: 200,
          },
          {
            label: "Email Address",
            field: "email",
            sort: "asc",
            width: 100,
          },
          {
            label: "Phone Number",
            field: "phone_number",
            sort: "asc",
            width: 150,
          },  
          {
            label: "Brand",
            field: "brand",
            sort: "asc",
            width: 100,
          },

          {
            label: "Agent Name",
            field: "agentName",
            sort: "asc",
            width: 100,
          },
          {
            label: "Platform",
            field: "platform",
            sort: "asc",
            width: 100,
          },
          {
            label: "Payment Status",
            field: "payment_status",
            sort: "asc",
            width: 100,
          },
          {
            label: "Calling Comments",
            field: "calling_comment",
            sort: "asc",
            width: 100,
          },
          {
            label: "Comments",
            field: "comment",
            sort: "asc",
            width: 100,
          },
        ];
  useEffect(() => {
    if (!isLoaded) {
      getAllMainLeads();
    }
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      getAllMainLeads();
    }
  }, [pagination.page, pagination.limit]);


  const setTableData = (data) => {
    return data.map((item) => {
      return {
        ...item,
        phoneNumber : item.phone_number,
        phone_number : 
        typeof item.phone_number == "object" ? (item.phone_number):
        item.phone_number ? <>
        <span title="Click to copy" id={item.phone_number} className="clickToCopy" onClick={()=>{
          navigator.clipboard.writeText(item.phone_number);
          //window.alert("Phone Number Copied!")
          const element = document.querySelector('.isclickedText');  // Select the first element with class 'iscliacked'
          if (element) {
            element.textContent = 'Text Copied to Clipboard !';  // Modify the text inside xxtthe element
            element.style.padding = '10px';  // Modify the text inside xxtthe element
            
            setTimeout(() => {
              element.textContent = '';  // Modify the text inside the element
              element.style.padding = '0px';  // Modify the text inside xxtthe element              
            }, 1000);
          }
          }}
        >{item.phone_number}
        <ContentCopyIcon  style={{fontSize : "18px" , marginLeft : "5px" , color : "#d4d4d4"}}/>
        </span>
        </> : "",
        agentName: item.agent_name ? item.agent_name.user_name : "",
        payment_status:
          typeof item.payment_status == "object" ? (
            item?.payment_status
          ) : item.payment_status ? (
            <>
              <span>
                <Typography
                  className="payment-tag"
                  style={{
                    borderRadius: "5px",
                    background: `${
                      item.payment_status === "paid" ||
                      item.payment_status === "Paid"
                        ? "green"
                        : item.payment_status === "new" ||
                          item.payment_status === "New"
                        ? "yellow"
                        : "red"
                    }`,
                    color: `${
                      item.payment_status === "new" ||
                      item.payment_status === "New"
                        ? "black"
                        : "white"
                    }`,
                  }}
                >
                  {item.payment_status}
                </Typography>
              </span>
            </>
          ) : (
            ""
          ),
        date: item?.date?.split("T")[0],
        time: item?.time
          ? item?.time
          : item?.date?.split("T")[1]?.split(".")[0],
        action: (
          <>
            <div>
              <span
                onClick={() => navigate(`/mainlead/${item._id}_readOnly`)}
                title="view"
                style={{ color: `${lightBackground}`, cursor: "pointer" }}
              >
                <RemoveRedEyeIcon />
              </span>
              {(item.payment_status != "paid" &&
                item.payment_status != "Paid") ||
              userType === "admin" ? (
                <>
                  {" "}
                  <span
                    onClick={() => navigate(`/mainlead/${item._id}_update`)}
                    style={{
                      color: `${lightBackground}`,
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    title="edit"
                  >
                    <CreateIcon />
                  </span>
                  <span
                    style={{
                      color: `${lightBackground}`,
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {/* <SyncAltIcon /> */}
                  </span>
                  {item.payment_status !== "paid" &&
                  item.payment_status !== "Paid" ? (
                    <>
                      <span
                        onClick={() => {
                          if (
                            item.client_name &&
                            item.email &&
                            item.phone_number &&
                            item.brand &&
                            item.agent_name
                          ) {
                            navigate(`/convertedlead/${item._id}_CREATE`);
                          } else {
                            console.log("error");
                            setIsOpen(true);
                            setMessage("fill up the required fields");
                            setSeverity("warning");
                            setTimeout(() => {
                              setIsOpen(false);
                            }, 2000);
                          }
                        }}
                        title="convert"
                        style={{
                          color: `${lightBackground}`,
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <SyncAltIcon />
                      </span>{" "}
                    </>
                  ) : (
                    []
                  )}
                  {userType === "admin" ? (
                    <span
                      onClick={() => handleConfirmOpen(item._id)}
                      title="delete"
                      style={{
                        color: `${lightBackground}`,
                        cursor: "pointer",
                      }}
                    >
                      <DeleteIcon />
                    </span>
                  ) : (
                    []
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </>
        ),
      };
    });
  };

  const deleteLead = async (id) => {
    setDeltIsClicked(true);
    const response = await axios
      .post(
        `${url}/leads/archieve`,
        { _id: deltId },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then((data) => {
        console.log("data", data);
        if (data?.data?.data?.success) {
          setIsOpen(true);
          setMessage(data.data.data.message);
          setSeverity("success");
          setTimeout(() => {
            getAllMainLeads();
          }, 3000);
        } else {
          setIsOpen(true);
          setMessage(
            `Error Occurred ! : ${data?.data?.data?.message?.substring(0, 100)}`
          );
          setSeverity("error");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("unauthorized error");
          userUnAuthorized(dispatch);
        } else {
          console.log("network error");
          setIsOpen(true);
          setMessage(error.message);
          setSeverity("error");
        }
      });
    setTimeout(() => {
      setDeltId("");
      setDeltIsClicked(false);
      setIsOpen(false);
    }, 3000);
  };

  const getFilterMainLeads = async () => {
    let obj = {
      startDate,
      endDate,
    };
    if (userType === "admin") {
      obj = {
        ...obj,
        branch_code: branch,
      };
    }
    try {
      setIsLoaded(true);
      const response = await axios
        .post(`${url}/leads/filterLeadsByDate`, obj, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((data) => {
          setData({
            columns,
            rows: data.data.data.data.map((item) => ({
              ...item,
              agentName: item.agent_name ? item.agent_name.user_name : "",
              payment_status: item.payment_status ? (
                <>
                  <span>
                    <Typography
                      style={{
                        borderRadius: "5px",
                        background: `${
                          item.payment_status === "paid" ||
                          item.payment_status === "Paid"
                            ? "green"
                            : item.payment_status === "new" ||
                              item.payment_status === "New"
                            ? "yellow"
                            : "red"
                        }`,
                        color: `${
                          item.payment_status === "new" ||
                          item.payment_status === "New"
                            ? "black"
                            : "white"
                        }`,
                      }}
                    >
                      {item.payment_status}
                    </Typography>
                  </span>
                </>
              ) : (
                ""
              ),
              date: item.date.split("T")[0],
              time: item.date.split("T")[1].split(".")[0],
              action: (
                <>
                  <div style={{ minWidth: "150px" }}>
                    <span
                      onClick={() => navigate(`/mainlead/${item._id}_readOnly`)}
                      title="view"
                      style={{ color: `${lightBackground}`, cursor: "pointer" }}
                    >
                      <RemoveRedEyeIcon />
                    </span>
                    {(item.payment_status != "paid" &&
                      item.payment_status != "Paid") ||
                    userType === "admin" ? (
                      <>
                        {" "}
                        <span
                          onClick={() =>
                            navigate(`/mainlead/${item._id}_update`)
                          }
                          style={{
                            color: `${lightBackground}`,
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                          title="edit"
                        >
                          <CreateIcon />
                        </span>
                        <span
                          style={{
                            color: `${lightBackground}`,
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {/* <SyncAltIcon /> */}
                        </span>
                        {item.payment_status !== "paid" &&
                        item.payment_status !== "Paid" ? (
                          <>
                            <span
                              onClick={() => {
                                if (
                                  item.client_name &&
                                  item.email &&
                                  item.phone_number &&
                                  item.brand &&
                                  item.agent_name
                                ) {
                                  navigate(`/convertedlead/${item._id}_CREATE`);
                                } else {
                                  console.log("error");
                                  setIsOpen(true);
                                  setMessage("fill up the required fields");
                                  setSeverity("warning");
                                  setTimeout(() => {
                                    setIsOpen(false);
                                  }, 2000);
                                }
                              }}
                              title="convert"
                              style={{
                                color: `${lightBackground}`,
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                            >
                              <SyncAltIcon />
                            </span>{" "}
                          </>
                        ) : (
                          []
                        )}
                        {userType === "admin" ? (
                          <span
                            onClick={() => handleConfirmOpen(item._id)}
                            title="delete"
                            style={{
                              color: `${lightBackground}`,
                              cursor: "pointer",
                            }}
                          >
                            <DeleteIcon />
                          </span>
                        ) : (
                          []
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ),
            })),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");
          setIsLoaded(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  const handleScreenSearch = (text) => {
    setData({
      ...filteredData,
      rows: filterDataArray(text, filteredData.rows),
    });
    // console.log("handleScreenSearch", text, data);
  };
  const getAllMainLeads = async (page, text, limit) => {
    try {
      setIsLoaded(true);

      // console.log("Text : ", text);
      let tempObj = {
        page:
          page !== undefined && page !== null && page !== ""
            ? page
            : pagination.page,
        text:
          text !== undefined && text !== null && text !== ""
            ? text
            : pagination.text,
        limit:
          limit !== undefined && limit !== null && limit !== ""
            ? limit
            : pagination.limit,
      };
      // setPagination({
      //   ...pagination,
      //   text: tempObj.text
      // });
      console.log("getAllMainLeads", pagination, tempObj);
      let obj = {
        startDate,
        endDate ,
      };
      if (userType === "admin") {
        obj = {
          ...obj,
          filterbranch: branch,
        };
      }
      const response = await axios
        .post(
          `${url}/leads/getAllMainLeads?limit=${tempObj.limit}&text=${tempObj.text}&page=${tempObj.page}`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((data) => {
          if (data.data.data.pagination.totalPages > pagination.buttonsCount) {
            setPagination({
              ...pagination,
              ...data?.data?.data?.pagination,
              page: data?.data?.data?.pagination.currentPage,
              totalPages: data?.data?.data?.pagination.totalPages,
              totalRecords: data?.data?.data?.pagination.totalRecords,
              text : tempObj.text
            });
          } else {
            setPagination({
              ...pagination,
              ...data?.data?.data?.pagination,
              page: data?.data?.data?.pagination.currentPage,
              totalPages: data?.data?.data?.pagination.totalPages,
              totalRecords: data?.data?.data?.pagination.totalRecords,
              text : tempObj.text
            });
          }
          // console.log("pagination", data.data.data.pagination , pagination);
          setFilteredData({
            columns,
            rows: setTableData(data?.data?.data?.data),
          });
          setData({
            columns,
            rows: setTableData(data?.data?.data?.data),
          });
          setIsOpen(true);
          setMessage("Data Loaded!");
          setSeverity("success");
          setIsLoaded(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
    } catch (error) {
      console.log("error", error.message);
      setIsLoaded(false);
      setIsOpen(true);
      setMessage(error.message);
      setSeverity("error");
    }
    setTimeout(() => {
      setIsOpen(false);
    }, 2000);
  };

  return (
    <>
      {" "}
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        {deltIsClicked ? (
          <span
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)", // Dim background color
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 99999,
            }}
          >
            <CircularProgress style={{ color: "black" }} />
          </span>
        ) : (
          ""
        )}
        <Paper fullWidth sx={{ m: 1, p: 2, maxWidth: "100%" }}>
          <span className="isclickedText"></span>
          <div class="content-outside">
            <Grid style={{ display: "flex", justifyContent: "flex-start" }}>
              <Typography variant="h5" color={`${lightText} `}>
                Main Leads
              </Typography>
            </Grid>
            <Grid
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                // paddingBottom: "20px",
              }}
            >
              {/* <Button
              onClick={getAllMainLeads}
              variant="contained"
              style={{ background: `${lightBackground}`, color: `${white}` }}
            >
              {isLoaded ? (
                <span>
                  {" "}
                  <CircularProgress
                    style={{
                      color: `${white}`,
                      height: "20px",
                      width: "20px",
                    }}
                  />
                </span>
              ) : (
                <>
                  <RefreshIcon sx={{ mr: 1 }} />
                  Reload
                </>
              )}
            </Button> */}

              <Grid container md={6} xs={12}>
                <Grid container item md={3} xs={12} sx={{ p: 1 }}>
                  <BasicDatePicker
                    label={"Start Date"}
                    value={startDate}
                    setValue={setStartDate}
                    item
                    disabled={isLoaded}
                  />
                </Grid>
                <Grid container item md={3} xs={12} sx={{ p: 1 }}>
                  <BasicDatePicker
                    label={"End Date"}
                    value={endDate}
                    setValue={setEndDate}
                    item
                    disabled={isLoaded}
                  />
                </Grid>
                {userType === "admin" ? (
                  <Grid item container md={3} xs={12} sx={{ p: 1 }}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Branch
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={branch}
                        margin="normal"
                        label="Select Agent"
                        onChange={(e) => setBranch(e.target.value)}
                        disabled={isLoaded}
                      >
                        <MenuItem value={"ALL-Br"}>All-Branches</MenuItem>
                        <MenuItem value={"KHI"}>Karachi</MenuItem>
                        <MenuItem value={"ISB"}>Islamabad</MenuItem>
                      </Select>
                    </FormControl>
                    {/* <TextField
                  name="agentName"
                  label="Select Agent"
                  type="text"
                  margin="normal"
                  fullWidth
                  disabled={isReadOnly}
                  value={agent}
                  onChange={(e) => setAgent(e.target.value)}
                /> */}
                  </Grid>
                ) : (
                  []
                )}
                <Grid container item md={3} xs={12} sx={{ p: 3 }}>
                  <Button
                    item
                    onClick={() => handleSearchBar()}
                    variant="contained"
                    fullWidth
                    disabled={isLoaded}
                    style={{
                      background: `${lightBackground} `,
                      color: `${white} `,
                    }}
                  >
                    {isLoaded ? (
                      <span>
                        {" "}
                        <CircularProgress
                          style={{
                            color: `${white}`,
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </span>
                    ) : (
                      <>
                        <RefreshIcon sx={{ mr: 1 }} />
                        <span className="oneLine">Get Data</span>
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                item
                style={{ display: "flex", justifyItems: "flex-end" }}
                md={6}
                xs={12}
              >
                <Grid item container md={4} xs={0} sx={{ p: 3 }}></Grid>
                <Grid
                  item
                  container
                  md={4}
                  xs={12}
                  sx={{ pl: 3, pt: 3, pb: 3 }}
                >
                  {userType === "admin" ? (
                    <Button
                      item
                      fullWidth
                      onClick={async () => {
                        setIsSheetLoaded(true);
                        await axios
                          .post(
                            `${url}/leads/dx`,
                            {
                              link: url.split("/a")[0],
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${state.token}`,
                              },
                            }
                          )
                          .then((response) => {
                            console.log(response.data.data.data);
                            window.open(response.data.data.data, "_parent");
                          })
                          .catch((error) => {
                            if (
                              error.response &&
                              error.response.status === 401
                            ) {
                              console.log("unauthorized error");
                              userUnAuthorized(dispatch);
                            } else {
                              console.log("network error");
                              setIsLoaded(false);
                              setIsOpen(true);
                              setMessage(error.message);
                              setSeverity("error");
                            }
                          });
                        setIsSheetLoaded(false);
                      }}
                      variant="contained"
                      style={{
                        background: `${lightBackground}`,
                        color: `${white}`,
                      }}
                    >
                      {isSheetLoaded ? (
                        <span>
                          {" "}
                          <CircularProgress
                            style={{
                              color: `${white}`,
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </span>
                      ) : (
                        <>
                          <SimCardDownloadIcon sx={{ mr: 1 }} />
                          <span className="oneLine">Excel Sheet</span>
                        </>
                      )}
                    </Button>
                  ) : (
                    []
                  )}
                </Grid>
                <Grid item container md={4} xs={12} sx={{ p: 3 }}>
                  <Button
                    item
                    fullWidth
                    onClick={() => navigate(`/mainlead/00000000_CREATE`)}
                    style={{
                      marginRight: "5px",
                      background: `${lightBackground}`,
                      color: `${white}`,
                    }}
                  >
                    <AddCircleOutlineIcon sx={{ mr: 1 }} />
                    <span className="oneLine">Add new lead</span>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div className="dataTableNew">
            <div className="flex-spaceBetween">
              <div className="dropDownEntries">
                <label htmlFor="entries" className="labelEntries">
                  Show entries:{" "}
                </label>
                <select
                  id="entries"
                  value={pagination.limit}
                  disabled={isLoaded}
                  onChange={(e) => {
                    handlePageData({
                      ...pagination,
                      limit: e.target.value,
                      page: 1,
                    });
                    sessionStorage.setItem("limit", e.target.value);
                  }}
                >
                  {[10, 20, 50, 100].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="searchBox">
                <DynamicSearchInput
                  handleSearchBar={(text) => handleSearchBar(text)}
                  handleScreenSearch={(text) => handleScreenSearch(text)}
                />
              </div>
            </div>

            <DynamicSearchTable data={data} isLoaded={isLoaded} />
            <Pagination
              pageData={pagination}
              setPageData={(data) => {
                handlePageData(data);
              }}
              isLoaded={isLoaded}
            />
          </div>
          {/* <DatatablePage data={data} isLoaded={isLoaded} /> */}
        </Paper>
      </Container>
      <SessionExpired
        open={confirmIsOpen}
        handleClose={handleConfirmYes}
        handleNo={handleConfirmClose}
        title={"Confirmation"}
        msgContent={"Are you sure. Want to delete this record ?"}
        btnMsg={"Yes"}
        closebtn={"No"}
      />
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default MainLeadsList;
