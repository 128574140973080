import io from "socket.io-client";
import {socketbaseurl } from "../baseUrl";

export const socket = io(socketbaseurl, {
  autoConnect: false,
  reconnection: true, // Enable reconnection
  reconnectionAttempts: Infinity, // Number of reconnection attempts, set to Infinity for unlimited
  reconnectionDelay: 1000, // Delay between reconnection attempts in milliseconds
  reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts
});
