import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { url } from "../../baseUrl";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { lightBackground, white } from "../../Assets/Theme/ThemeColors";
import CustomizedSnackbars from "../../Components/SnackBar/SnackBar";
import isAlpha from "validator/lib/isAlpha";
import isEmail from "validator/lib/isEmail";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { userUnAuthorized } from "../../redux/actions/commonAction";

const MainLeadsForm = () => {
  let { id } = useParams();
  let param = id.split("_");
  let leadId = param[0];
  let accessType = param[param.length - 1];

  const state = useSelector((state) => state.user.data);
  // console.log("state" , state?.data?.user?.branch_code)
  const userBranch = state?.data?.user?.branch_code;
  const userType = state.data.user.user_type;
  // console.log("state", userType);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [brand, setBrand] = useState("");
  const [chooseService, setChooseService] = useState("");
  const [agent, setAgent] = useState("");
  const [platform, setPlatform] = useState("");
  const [payment, setPayment] = useState("");
  const [calling, setCalling] = useState("");
  const [comment, setComment] = useState("");
  const [month, setMonth] = useState("");
  const [tableData, setTableData] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const [date, setDate] = useState(
    `${
      new Date().getMonth() +
      1 +
      " / " +
      new Date().getDate() +
      " / " +
      new Date().getFullYear()
    }`
  );

  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const [branch, setBranch] = useState("KHI");

  const [isdisabled, setIsdisabled] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const [isTableLoaded, setIsTableLoaded] = useState(false);

  const columns = [
    {
      id: 1,
      name: "Agent Name",
    },
    {
      id: 2,
      name: "Date",
    },
    {
      id: 3,
      name: "Time",
    },
  ];

  // console.log("user", state);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitForm = async () => {
    if (name && email && phone && brand && platform) {
      setIsLoaded(true);

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const d = new Date();
      var data = {};

      setMonth(monthNames[d.getMonth()]);
      if (accessType === "CREATE") {
        data = {
          month: monthNames[d.getMonth()],
          client_name: name,
          email,
          phone_number: phone,
          brand,
          agent_name: agent,
          platform,
          payment_status: payment,
          calling_comment: calling,
          comment,
          branch_code: userType === "admin" ? branch : userBranch,
          chooseService: chooseService,
        };
      } else {
        data = {
          _id: leadId,
          month: monthNames[d.getMonth()],
          client_name: name,
          email,
          phone_number: phone,
          brand,
          agent_name: agent,
          platform,
          payment_status: payment,
          calling_comment: calling,
          comment,
          chooseService: chooseService,
        };
      }
      // console.log("edit", data);
      await axios
        .post(
          `${url}${accessType === "CREATE" ? "/leads" : "/leads/editLead"}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(function (response) {
          if (response.data.data.success) {
            // console.log(response.data.data.data);
            // console.log("true");
            setIsOpen(true);
            setMessage(
              accessType === "CREATE" ? "New Lead Created!" : "Record Updated !"
            );
            setSeverity("success");
            setTimeout(() => {
              navigate("/mainlead/list");
            }, 2000);
            // setIsLoaded(false);
          } else {
            setIsOpen(true);
            setMessage(response.data.data.message);
            setSeverity("error");
            console.log(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });

      setTimeout(() => {
        setIsOpen(false);
        setIsLoaded(false);
      }, 2000);
    } else {
      setIsOpen(true);
      setMessage("fill the required fields");
      setSeverity("warning");
      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    }
  };

  useEffect(() => {
    getAllUsers();
    if (accessType !== "CREATE") getSpecific();
  }, []);

  const getSpecific = async () => {
    setIsTableLoaded(false);
    const response = await axios
      .post(
        `${url}/leads/getSpecific`,
        {
          _id: leadId,
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then((response) => {
        let data = response.data.data.data;
        // console.log("data", data);
        setIsdisabled(true);

        setName(data.client_name ? data.client_name : "");
        setEmail(data.email ? data.email : "");
        setPhone(data.phone_number ? data.phone_number : "");
        setBrand(data.brand ? data.brand : "");
        setChooseService(data.chooseService ? data.chooseService : "");
        setAgent(
          data.agent_name
            ? data.agent_name._id
              ? data.agent_name._id
              : ""
            : ""
        );
        setPlatform(data.platform ? data.platform : "");
        setPayment(data.payment_status ? data.payment_status : "");
        setCalling(data.calling_comment ? data.calling_comment : "");
        setComment(data.comment ? data.comment : "");
        setDate(
          data.date
            ? data.date.split("T")[0] +
                "     |    " +
                data.date.split("T")[1].split(".")[0]
            : ""
        );
        setBranch(data.branch_code ? data.branch_code : "")

        let rows = [];
        data.lastModified.map((row) => {
          rows.push({
            ...row,
            date: row.date.split("T")[0],
            time: row.date.split("T")[1].split(".")[0],
          });
        });
        let obj = {
          columns: columns,
          rows,
        };
        setTableData(obj);
        // console.log("datatable");
        setIsTableLoaded(true);
        //reaOnly and update
        accessType === "readOnly" ? setIsReadOnly(true) : setIsReadOnly(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("unauthorized error");
          userUnAuthorized(dispatch);
        } else {
          console.log("network error");
          setIsLoaded(false);
          setIsOpen(true);
          setMessage(error.message);
          setSeverity("error");
        }
      });
  };

  const getAllUsers = async () => {
    try {
      await axios
        .get(`${url}/auth/getAllUsers`, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then((response) => {
          console.log(
            "filtered response",
            response.data.data.data.filter(
              (user) =>
                user.user_type === "sales_agent" &&
                user.active === true &&
                (userBranch === "ALL-Br"
                  ? true
                  : user.branch_code === userBranch)
            )
          );
          console.log(
            "all response",
            response.data.data.data.filter(
              (user) => user.user_type === "sales_agent"
            )
          );

          setAllUsers(
            response.data.data.data.filter(
              (user) =>
                user.user_type === "sales_agent" &&
                user.active === true &&
                (userBranch === "ALL-Br"
                  ? true
                  : user.branch_code === userBranch)
            )
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.log("unauthorized error");
            userUnAuthorized(dispatch);
          } else {
            console.log("network error");
            setIsLoaded(false);
            setIsOpen(true);
            setMessage(error.message);
            setSeverity("error");
          }
        });
    } catch (error) {}
  };
  return (
    <>
      <Container
        style={{
          maxWidth: "100%",
          padding: "0px",
          overflowY: "scroll",
          height: "90vh",
        }}
      >
        <Box sx={{ mt: 3 }}>
          <Paper sx={{ p: 3, mr: 2, ml: 2 }}>
            <Grid container sx={{ mt: 2, p: 3 }}>
              <Typography variant="h5" color="Primary">
                Main Lead
              </Typography>
            </Grid>
            <Grid item container className="Row" sx={{ p: 1 }}>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="date"
                  label="Date / Time"
                  // type="date"
                  margin="normal"
                  disabled={true}
                  fullWidth
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                {userType === "admin" ? (
                  <FormControl margin="normal" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Branch
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={branch}
                      margin="normal"
                      label="Select Agent"
                      onChange={(e) => setBranch(e.target.value)}
                      disabled={isReadOnly}
                    >
                      <MenuItem value={"KHI"}>Karachi</MenuItem>
                      <MenuItem value={"ISB"}>Islamabad</MenuItem>
                      <MenuItem value={""}>None</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  []
                )}
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                {userType === "admin" ? (
                  <TextField
                    name="chooseService"
                    label="Choose Service"
                    type="text"
                    margin="normal"
                    fullWidth
                    disabled={isReadOnly}
                    value={chooseService}
                    onChange={(e) => setChooseService(e.target.value)}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="clientName"
                  label="Client Name"
                  type="text"
                  margin="normal"
                  fullWidth
                  disabled={isReadOnly}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="clientEmail"
                  label="Client Email"
                  type="text"
                  margin="normal"
                  fullWidth
                  disabled={isReadOnly}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="clientPhone"
                  label="Client Phone No."
                  type="text"
                  margin="normal"
                  fullWidth
                  disabled={isReadOnly}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="brand"
                  label="Brand Name"
                  type="text"
                  margin="normal"
                  fullWidth
                  disabled={isReadOnly}
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Agent
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={agent}
                    margin="normal"
                    label="Select Agent"
                    onChange={(e) => setAgent(e.target.value)}
                    disabled={isReadOnly}
                  >
                    {console.log("allUsers", allUsers)}
                    {allUsers.length > 0 ? (
                      allUsers.map((user) => (
                        <MenuItem value={user._id}>{user.user_name}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""}>none</MenuItem>
                    )}
                  </Select>
                </FormControl>
                {/* <TextField
                  name="agentName"
                  label="Select Agent"
                  type="text"
                  margin="normal"
                  fullWidth
                  disabled={isReadOnly}
                  value={agent}
                  onChange={(e) => setAgent(e.target.value)}
                /> */}
              </Grid>
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="platform"
                  label="Platform"
                  type="text"
                  margin="normal"
                  fullWidth
                  disabled={isReadOnly}
                  value={platform}
                  onChange={(e) => setPlatform(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={4} xs={12} sx={{ p: 1 }}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Payment Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={payment}
                    margin="normal"
                    label="Payment Status"
                    onChange={(e) => setPayment(e.target.value)}
                    disabled={
                      isReadOnly ? true : userType === "admin" ? false : true
                    }
                  >
                    <MenuItem value={"paid"}>Paid</MenuItem>
                    <MenuItem value={"unpaid"}>Unpaid</MenuItem>
                    <MenuItem value={"new"}>New</MenuItem>
                    {/* <MenuItem value={"Completed"}>Completed</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container md={8} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="callingComemnt"
                  label="Calling Comment"
                  type="text"
                  margin="normal"
                  fullWidth
                  disabled={isReadOnly}
                  value={calling}
                  onChange={(e) => setCalling(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container className="Row">
              <Grid item container md={12} xs={12} sx={{ p: 1 }}>
                <TextField
                  name="comment"
                  label="Comment"
                  multiline
                  rows={4}
                  type="text"
                  margin="normal"
                  fullWidth
                  disabled={isReadOnly}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </Grid>
            </Grid>
            {accessType === "readOnly" ? (
              <Grid>
                <Typography variant="h5">Lead History</Typography>
                {isTableLoaded ? (
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead style={{ color: "white" }}>
                        <TableRow>
                          <TableCell>Agent name</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {console.log(tableData)}
                        {tableData.rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.agent}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.date}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.time}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      style={{
                        // color: `${white}`,s
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  </span>
                )}
              </Grid>
            ) : (
              []
            )}
            <Grid
              container
              className="Row"
              sx={{ mt: 2 }}
              style={{
                gap: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item container md={1} xs={1}>
                <Button
                  type="clear"
                  style={{
                    border: `1px solid ${lightBackground}`,
                    color: `${lightBackground}`,
                    background: `${white}`,
                  }}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  onClick={() => {
                    navigate("/mainlead/list");
                  }}
                >
                  back
                </Button>
              </Grid>
              {accessType !== "readOnly" ? (
                <Grid item container md={1} xs={1}>
                  <Button
                    onClick={submitForm}
                    variant="contained"
                    style={{
                      background: `${lightBackground}`,
                      color: `${white}`,
                    }}
                    margin="normal"
                    fullWidth
                    disabled={isLoaded}
                  >
                    {isLoaded ? (
                      <span>
                        {" "}
                        <CircularProgress
                          style={{
                            color: `${white}`,
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </span>
                    ) : accessType === "CREATE" ? (
                      "save"
                    ) : (
                      "UPDATE"
                    )}
                  </Button>
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Paper>
        </Box>
      </Container>
      <CustomizedSnackbars
        isOpen={isOpen}
        severity={severity}
        message={message}
      />
    </>
  );
};

export default MainLeadsForm;
