import React from "react";
import { MDBDataTable } from "mdbreact";
import { CircularProgress, Container, Paper, Typography } from "@mui/material";

const DatatablePage = ({ data, isLoaded }) => {
  // console.log("data table", data);
  return (
    <div className="DataTable-Container">
      {isLoaded == false ? (
      <MDBDataTable compact hover responsive data={data} />
      ) : (
      <CircularProgress />
      )}
    </div>
  );
};

export default DatatablePage;
